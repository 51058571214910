define("discourse/plugins/discourse-follow/discourse/templates/feed", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.model.noContent}}
    {{#if this.viewingSelf}}
      <div class="alert alert-info">{{i18n "user.feed.empty_feed_you"}}</div>
    {{else}}
      <div class="alert alert-info">{{i18n
          "user.feed.empty_feed_other"
          username=this.user.username
        }}</div>
    {{/if}}
  {{else}}
    {{follow-post-stream stream=this.model}}
  {{/if}}
  */
  {
    "id": "HHUJTOCR",
    "block": "[[[41,[30,0,[\"model\",\"noContent\"]],[[[41,[30,0,[\"viewingSelf\"]],[[[1,\"    \"],[10,0],[14,0,\"alert alert-info\"],[12],[1,[28,[35,1],[\"user.feed.empty_feed_you\"],null]],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"alert alert-info\"],[12],[1,[28,[35,1],[\"user.feed.empty_feed_other\"],[[\"username\"],[[30,0,[\"user\",\"username\"]]]]]],[13],[1,\"\\n\"]],[]]]],[]],[[[1,\"  \"],[1,[28,[35,2],null,[[\"stream\"],[[30,0,[\"model\"]]]]]],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"i18n\",\"follow-post-stream\"]]",
    "moduleName": "discourse/plugins/discourse-follow/discourse/templates/feed.hbs",
    "isStrictMode": false
  });
});