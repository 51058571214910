define("discourse/plugins/discourse-follow/discourse/templates/connectors/user-main-nav/follow-user-container", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.model.can_see_network_tab}}
    <LinkTo @route="follow">
      {{d-icon "users"}}
      <span>{{i18n "user.follow_nav"}}</span>
    </LinkTo>
  {{/if}}
  */
  {
    "id": "FCtXf5nl",
    "block": "[[[41,[30,0,[\"model\",\"can_see_network_tab\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@route\"],[\"follow\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,2],[\"users\"],null]],[1,\"\\n    \"],[10,1],[12],[1,[28,[35,3],[\"user.follow_nav\"],null]],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"link-to\",\"d-icon\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-follow/discourse/templates/connectors/user-main-nav/follow-user-container.hbs",
    "isStrictMode": false
  });
});