define("discourse/plugins/discourse-follow/discourse/templates/follow", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{body-class "user-follow-page"}}
  
  <div class="user-navigation user-navigation-secondary">
    <HorizontalOverflowNav>
      {{#if (eq this.model.id this.currentUser.id)}}
        <DNavigationItem @route="feed">
          <span>{{i18n "user.feed.label"}}</span>
        </DNavigationItem>
      {{/if}}
  
      {{#if this.model.can_see_following}}
        <DNavigationItem @route="following">
          <span>{{i18n "user.following.label"}}</span>
        </DNavigationItem>
      {{/if}}
  
      {{#if
        (and this.model.can_see_followers this.model.allow_people_to_follow_me)
      }}
        <DNavigationItem @route="followers">
          <span>{{i18n "user.followers.label"}}</span>
        </DNavigationItem>
      {{/if}}
    </HorizontalOverflowNav>
  </div>
  
  <section class="user-content user-follows-tab">
    {{outlet}}
  </section>
  */
  {
    "id": "WerpZEDd",
    "block": "[[[1,[28,[35,0],[\"user-follow-page\"],null]],[1,\"\\n\\n\"],[10,0],[14,0,\"user-navigation user-navigation-secondary\"],[12],[1,\"\\n  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[28,[37,3],[[30,0,[\"model\",\"id\"]],[30,0,[\"currentUser\",\"id\"]]],null],[[[1,\"      \"],[8,[39,4],null,[[\"@route\"],[\"feed\"]],[[\"default\"],[[[[1,\"\\n        \"],[10,1],[12],[1,[28,[35,5],[\"user.feed.label\"],null]],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"model\",\"can_see_following\"]],[[[1,\"      \"],[8,[39,4],null,[[\"@route\"],[\"following\"]],[[\"default\"],[[[[1,\"\\n        \"],[10,1],[12],[1,[28,[35,5],[\"user.following.label\"],null]],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[37,6],[[30,0,[\"model\",\"can_see_followers\"]],[30,0,[\"model\",\"allow_people_to_follow_me\"]]],null],[[[1,\"      \"],[8,[39,4],null,[[\"@route\"],[\"followers\"]],[[\"default\"],[[[[1,\"\\n        \"],[10,1],[12],[1,[28,[35,5],[\"user.followers.label\"],null]],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"section\"],[14,0,\"user-content user-follows-tab\"],[12],[1,\"\\n  \"],[46,[28,[37,8],null,null],null,null,null],[1,\"\\n\"],[13]],[],false,[\"body-class\",\"horizontal-overflow-nav\",\"if\",\"eq\",\"d-navigation-item\",\"i18n\",\"and\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-follow/discourse/templates/follow.hbs",
    "isStrictMode": false
  });
});