define("discourse/plugins/discourse-follow/discourse/components/follow-users-list", ["exports", "@ember/component", "@ember/object/computed", "discourse/lib/computed", "discourse-common/utils/decorators", "@ember/template-factory"], function (_exports, _component, _computed, _computed2, _decorators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="follow-users">
    {{#if this.hasUsers}}
      {{#each this.users as |user|}}
        {{user-info user=user}}
      {{/each}}
    {{else}}
      <div class="alert alert-info">{{i18n
          this.noneMessage
          username=this.user.username
        }}</div>
    {{/if}}
  </div>
  */
  {
    "id": "d2YDtwtW",
    "block": "[[[10,0],[14,0,\"follow-users\"],[12],[1,\"\\n\"],[41,[30,0,[\"hasUsers\"]],[[[42,[28,[37,2],[[28,[37,2],[[30,0,[\"users\"]]],null]],null],null,[[[1,\"      \"],[1,[28,[35,3],null,[[\"user\"],[[30,1]]]]],[1,\"\\n\"]],[1]],null]],[]],[[[1,\"    \"],[10,0],[14,0,\"alert alert-info\"],[12],[1,[28,[35,4],[[30,0,[\"noneMessage\"]]],[[\"username\"],[[30,0,[\"user\",\"username\"]]]]]],[13],[1,\"\\n\"]],[]]],[13]],[\"user\"],false,[\"if\",\"each\",\"-track-array\",\"user-info\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-follow/discourse/components/follow-users-list.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend(dt7948.p({
    hasUsers: (0, _computed.notEmpty)("users"),
    viewingSelf: (0, _computed2.propertyEqual)("user.username", "currentUser.username"),
    noneMessage(type, viewingSelf) {
      let key = viewingSelf ? "none" : "none_other";
      return `user.${type}.${key}`;
    }
  }, [["method", "noneMessage", [(0, _decorators.default)("type", "viewingSelf")]]])));
});